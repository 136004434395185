// extracted by mini-css-extract-plugin
export var article = "blog-post-module--article--75029";
export var center = "blog-post-module--center--1862a";
export var cta = "blog-post-module--cta--36cbf";
export var ctaText = "blog-post-module--cta-text--08502";
export var date = "blog-post-module--date--62da1";
export var full = "blog-post-module--full--82117";
export var hero = "blog-post-module--hero--11514";
export var heroContent = "blog-post-module--hero-content--ad116";
export var heroImage1 = "blog-post-module--hero-image-1--37742";
export var heroImage2 = "blog-post-module--hero-image-2--0551f";
export var image = "blog-post-module--image--26126";
export var intro = "blog-post-module--intro--e36b1";
export var left = "blog-post-module--left--35091";
export var outro = "blog-post-module--outro--5c53e";
export var right = "blog-post-module--right--c6ef9";
export var textBlock = "blog-post-module--text-block--a4b29";
export var wrapper = "blog-post-module--wrapper--02980";