import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Header from "../components/header";
import Container from "../components/container";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import * as Styles from "./blog-post.module.css";

const BlogPost = ({ data }) => {
  console.log(data);
  return (
    <Layout hasFooter={true}>
      <Helmet>
        <title>{data.contentfulBlogPost.heading}</title>
        <meta
          name="description"
          content="Tom Laker is a graphic designer and photographer based in Stockholm. Originating from the South coast of England Tom has lived and worked all over the world, from Hong Kong to Australia and now Sweden. His work has been shaped by the environments he’s lived and by the strong morals he holds."
        />
      </Helmet>
      <Header light={true} absolute={true} />
      <div className={Styles.hero}>
        <GatsbyImage
          image={data.contentfulBlogPost.headerImages[0].gatsbyImageData}
          className={Styles.heroImage1}
        />
        <GatsbyImage
          image={data.contentfulBlogPost.headerImages[1].gatsbyImageData}
          className={Styles.heroImage2}
        />
        <div className={Styles.heroContent}>
          <div className={Styles.wrapper}>
            <div>{data.contentfulBlogPost.location}</div>
            <h1>{data.contentfulBlogPost.heading}</h1>
            {/* <div className={Styles.date}>{{article.date}}</div> */}
          </div>
        </div>
      </div>
      <article className={Styles.article}>
        <Container>
          <div className={Styles.intro}>
            <p>{data.contentfulBlogPost.description.description}</p>
          </div>
          {data.contentfulBlogPost.body.map((el) => {
            if (el.image) {
              return (
                <div
                  className={`${Styles.image} ${
                    Styles[el.layoutType.toLowerCase()]
                  }`}
                >
                  <GatsbyImage image={el.image.gatsbyImageData} />
                </div>
              );
            } else if (el.quote) {
              return <div className={Styles.textBlock}>{el.quote.quote}</div>;
            } else {
              return <div></div>;
            }
          })}

          <div className={Styles.outro}>
            <p>{data.contentfulBlogPost.footer.footer}</p>
          </div>
        </Container>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($heading: String!) {
    contentfulBlogPost(heading: { eq: $heading }) {
      heading
      location
      headerImages {
        gatsbyImageData
      }
      description {
        description
      }
      body {
        ... on ContentfulBlogPhotograph {
          id
          layoutType
          image {
            gatsbyImageData
          }
        }
        ... on ContentfulBlogQuote {
          id
          quote {
            quote
          }
        }
      }
      footer {
        footer
      }
    }
  }
`;

export default BlogPost;
